<script setup>
import { computed } from 'vue'
import AppLayout from '@/Layouts/AppLayout.vue'
import { ExclamationCircleIcon } from '@heroicons/vue/24/outline/index.js'
import { trans } from '@/vendor/inertia-scaffold/trans.js'
import { _ } from 'lodash'

const props = defineProps({
    status: Number,
    contentOnly: {
        type: Boolean,
        default: false,
    },
    administrationLayout: {
        type: Boolean,
        default: false,
    },
})

const title = computed(() => {
    switch (_.toInteger(props.status)) {
        case 503:
            return 'Error 503: ' + trans('error.service_unavailable')
        case 500:
            return 'Error 500: ' + trans('error.server_error')
        case 404:
            return 'Error 404: ' + trans('error.page_not_found')
        case 403:
            return 'Error 403: ' + trans('error.forbidden')
        default:
            return 'Error ' + props.status + ': ' + trans('error.unknown_error')
    }
})

const description = computed(() => {
    return {
        503: trans('error.service_unavailable_content'),
        500: trans('error.server_error_content'),
        404: trans('error.page_not_found_content'),
        403: trans('error.forbidden_content'),
    }[props.status]
})
const goback = () => {
    history.go(-1)
}
</script>

<template>
    <AppLayout
        :title="trans('global.oops')"
        :breadcrumb="false"
        :content-only="props.contentOnly"
        :administration="props.administrationLayout"
    >
        <div class="flex flex-col items-center align-middle py-10">
            <exclamation-circle-icon class="!custom-stroke text-warning lg:w-1/5 w-1/2" />
            <h1 v-html="title" class="text-warning" />
            <div v-html="description" />
            <button type="button" @click="goback" class="hover:underline pt-3">
                {{ trans('global.go_back') }}
            </button>
        </div>
    </AppLayout>
</template>

<style scoped lang="scss"></style>
